import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const questionApi = createApi({
    reducerPath: 'question',
    baseQuery: createCustomFetchBaseQuery(),
    tagTypes: ['Question'],
    endpoints: (builder) => ({
        getAllQuestions: builder.query({
            query: ({ pageno, limit, search, system, region }) => `question/all?pageno=${pageno + 1}&limit=${limit}&search=${search}&system=${system}&region=${region}`,
            transformResponse: (res) => (res.body),
            providesTags: ['Question'],
        }),

        getQuestionById: builder.query({
            query: (id) => `/question/single/${id}`,
            transformResponse: (res) => (res.body),
            providesTags: ['Question'],

        }),

        addNewQuestion: builder.mutation({
            query: (newQuestion) => ({
                url: `/question/add`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: newQuestion,
            }),
            invalidatesTags: ['Question'],
        }),

        updateQuestion: builder.mutation({
            query: (data) =>
            (
                {
                    url: `question/update/${data._id}`,
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: data,
                }
            ),
            invalidatesTags: ['Question'],
        }),

        deleteQuestion: builder.mutation({
            query: (id) =>
            (
                {
                    url: `/question/delete/${id}`,
                    method: "DELETE"
                }
            ),
            invalidatesTags: ['Question']
        }),


        deleteAllQuestions: builder.mutation({
            query: (id) =>
            (
                {
                    url: `/question/delete`,
                    method: "DELETE"
                }
            ),
            invalidatesTags: ['Question']
        })
        ,uploadQuestionFile: builder.mutation({
            query: (data) => {
              const formData = new FormData();
              formData.append('question_file', data.question_file);
              return {
                url: `question/upload-bulk-questions`, 
                method: 'POST',
                body: formData,
              };
            },
          }),
    }),
});

export const {
    useGetQuestionByIdQuery,
    useGetAllQuestionsQuery,
    useAddNewQuestionMutation,
    useUpdateQuestionMutation,
    useDeleteQuestionMutation,
    useUploadQuestionFileMutation,
    useDeleteAllQuestionsMutation   
} = questionApi;
